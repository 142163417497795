<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<h5 class="mb-4">
							<strong>Detalles de Cliente</strong>
						</h5>
						<hr class="hrText" data-content="♦" />
					</div>
				</div>
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" tab="Información General" force-render>
						<div class="row">
							<div class="col-md-4">
								<b>Tipo de cliente</b>
								<a-select style="width: 100%" placeholder="Selecciona" v-model="clientType">
									<a-select-option value="fisica">Persona Física</a-select-option>
									<a-select-option value="moral">Persona Moral</a-select-option>
								</a-select>
							</div>
						</div>
						<clientsDetailGeneralComponent class="pt10" ref="clientsDetailGeneral" v-if="clientType == 'fisica'" />
						<clientsDetailGeneralMoralComponent class="pt10" ref="clientsDetailGeneralMoral" v-if="clientType == 'moral'" />
					</a-tab-pane>
					<a-tab-pane key="2" tab="Información Fiscal" force-render v-if="hasClientId">
						<clientsDetailBillingInformationComponent />
					</a-tab-pane>
					<a-tab-pane key="3" tab="Motocicletas" force-render v-if="hasClientId">
						<clientsDetailMotorcyclesComponent ref="clientsDetailMotorcycles" />
					</a-tab-pane>
				</a-tabs>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import clientsDetailGeneralComponent from '@/components/clients/detail/partials/general'
import clientsDetailGeneralMoralComponent from '@/components/clients/detail/partials/generalMoral'
import clientsDetailBillingInformationComponent from '@/components/clients/detail/partials/billingInformation'
import clientsDetailMotorcyclesComponent from '@/components/clients/detail/partials/motorcycles'

export default {
	name: 'clientDetailView',
	components: {
		clientsDetailGeneralComponent,
		clientsDetailBillingInformationComponent,
		clientsDetailMotorcyclesComponent,
		clientsDetailGeneralMoralComponent,
	},
	data() {
		return {
			clientType: 'fisica',
		}
	},
	computed: {
		...mapGetters('clients', ['actualClient']),
		hasClientId() {
			return !!utilities.objectValidate(this.actualClient, 'id', false)
		},
		spinnerLoaderLabel() {
			return this.$store.state.clients.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.clients.spinnerLoader
		},
	},
	destroyed() {
		this.$store.commit('clients/SET_STATE', {
			actualClient: {},
		})
	},
}
</script>