<template>
	<div>
		<a-form class="mb-4" :form="form" @submit="handleSubmit" ref="clientsDetailGeneralForm">
			<div class="row">
				<div class="col-md-12">
					<a-form-item class="m0" label="Nombre">
						<a-input
							v-decorator="[
								'client_name',
								{
									rules: [
										{
											required: true,
											message: 'Campo requerido.',
										},
									],
								},
							]"
							autocomplete="off"
						/>
					</a-form-item>
				</div>
			</div>
			<div class="row" v-show="showControls">
				<div class="col-md-12">
					<hr class="hrText" />
				</div>
				<div class="col-md-4 text-left">
					<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
				</div>
				<div class="col-md-8 text-right">
					<a-button icon="save" class="btn btn-success" htmlType="submit" id="clientDetailGeneralComponent_submit">Guardar</a-button>
				</div>
			</div>
		</a-form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { clientModel } from '@/constants'
import _ from 'lodash'
import utilities from '@/services/utilities'
import { mask } from 'vue-the-mask'

export default {
	name: 'clientDetailGeneralComponent',
	directives: {
		mask,
	},
	props: {
		showControls: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters('clients', ['actualClient']),
		hasClientId() {
			return !!utilities.objectValidate(this.actualClient, 'id', false)
		},
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
			client: _.cloneDeep(clientModel.general),
		}
	},
	beforeMount() {
		if (this.hasClientId) {
			this.client = _.cloneDeep({
				...this.client,
				...this.actualClient,
			})
		}
	},
	mounted() {
		let values = _.cloneDeep(this.client)

		this.form.setFieldsValue({
			client_name: values.client_name,
			client_surname: values.client_surname,
			client_last_surname: utilities.objectValidate(values, 'client_last_surname', ''),
			primary_phone: values.primary_phone,
			secondary_phone: utilities.objectValidate(values, 'secondary_phone', ''),
			email: utilities.objectValidate(values, 'email', ''),
		})
	},
	methods: {
		onCancel() {
			this.$router.replace('/pos/clients')
		},
		cleanData() {
			this.form.setFieldsValue({
				client_name: '',
				client_surname: '',
				client_last_surname: '',
				primary_phone: '',
				secondary_phone: '',
				email: '',
			})
			this.client = _.cloneDeep(clientModel.general)
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let payload = {
						client_name: values.client_name,
						client_surname: values.client_surname,
						client_last_surname: utilities.objectValidate(values, 'client_last_surname', ''),
						primary_phone: values.primary_phone,
						secondary_phone: utilities.objectValidate(values, 'secondary_phone', ''),
						email: utilities.objectValidate(values, 'email', ''),
						showControls: this.showControls,
					}

					if (this.hasClientId) {
						payload.id = this.actualClient.id
						this.$store.dispatch('clients/UPDATE', payload)
					} else {
						this.$store.dispatch('clients/CREATE', payload).then((response) => {
							if (!this.showControls) {
								this.$emit('selectedRecord', response)
								this.cleanData()
							}
						})
					}
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>