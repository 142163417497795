<template>
	<div>
		<div class="row">
			<div class="col-md-6">
				<a-form class="mb-4" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-8">
							<a-form-item class="m0" label="Razón Social">
								<a-input
									v-decorator="[
										'business_name',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-4">
							<a-form-item class="m0" label="R.F.C">
								<a-input
									v-decorator="[
										'rfc',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
												{
													min: 12,
													message: 'Ingresa RFC válido',
												},
												{
													max: 13,
													message: 'Ingresa RFC válido',
												},
											],
										},
									]"
									autocomplete="off"
									class="text-uppercase"
								/>
							</a-form-item>
						</div>
						<div class="col-md-7">
							<a-form-item class="m0" label="Régimen Fiscal">
								<a-select
									showSearch
									option-filter-prop="children"
									:filter-option="filterOption"
									v-decorator="[
										'tax_regime',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
								>
									<a-select-option v-for="regime in taxRegimes" :key="regime.id" :value="regime.id"> {{ regime.id }} - {{ regime.description }} </a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-md-5">
							<a-form-item class="m0" label="Email">
								<a-input
									v-decorator="[
										'email',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
												{
													email: true,
													message: 'Ingresa email válido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-4">
							<a-form-item class="m0" label="Código Postal">
								<a-input
									v-mask="'#####'"
									v-decorator="[
										'zip_code',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-8">
							<a-form-item class="m0" label="Domicilio (Calle)">
								<a-input v-decorator="['street_name']" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item class="m0" label="No. exterior">
								<a-input v-decorator="['external_number']" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item class="m0" label="No. interior">
								<a-input v-decorator="['internal_number']" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item class="m0" label="Colonia">
								<a-input v-decorator="['neighborhood']" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item class="m0" label="Alcaldía / Municipio">
								<a-input v-decorator="['locality']" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item class="m0" label="Estado">
								<a-input v-decorator="['state']" autocomplete="off" />
							</a-form-item>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<hr class="hrText" />
						</div>
						<div class="col-md-4 text-left">
							<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
						</div>
						<div class="col-md-8 text-right">
							<a-button class="btn btn-danger" v-if="showCancelButton" @click="onCancelEdit">Cancelar</a-button>
							<a-button icon="save" class="btn btn-success ml7" htmlType="submit">Guardar</a-button>
						</div>
					</div>
				</a-form>
			</div>
			<div class="col-md-6">
				<a-collapse accordion>
					<a-collapse-panel v-for="(element, index) in actualClient.billing_information" :key="index" :header="`${element.business_name} - ${element.rfc}`">
						<table class="table table-bordered table-condensed table-striped m0 tablePadding25">
							<tbody>
								<tr>
									<td width="35%"><b>Razón Social</b></td>
									<td>{{ element.business_name }}</td>
								</tr>
								<tr>
									<td width="35%"><b>RFC</b></td>
									<td>{{ element.rfc }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Régimen Fiscal</b></td>
									<td>{{ element.tax_regime_label }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Email</b></td>
									<td>{{ element.email }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Código Postal</b></td>
									<td>{{ element.zip_code }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Domicilio (Calle)</b></td>
									<td>{{ element.street_name | replaceIfLogicalFalse('-') }}</td>
								</tr>
								<tr>
									<td width="35%"><b>No. exterior</b></td>
									<td>{{ element.external_number | replaceIfLogicalFalse('-') }}</td>
								</tr>
								<tr>
									<td width="35%"><b>No. interior</b></td>
									<td>{{ element.internal_number | replaceIfLogicalFalse('-') }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Colonia</b></td>
									<td>{{ element.neighborhood | replaceIfLogicalFalse('-') }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Alcaldía / Municipio</b></td>
									<td>{{ element.locality | replaceIfLogicalFalse('-') }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Estado</b></td>
									<td>{{ element.state | replaceIfLogicalFalse('-') }}</td>
								</tr>
							</tbody>
						</table>
						<div class="row">
							<div class="col-md-12 text-right" style="padding: 10px 25px" v-if="isEditable">
								<a-button class="btn btn-danger" size="small" icon="delete" @click="onDeleteElement(index)" />
								<a-button class="btn btn-info ml7" size="small" icon="edit" @click="onEditElement(index)" />
							</div>
						</div>
					</a-collapse-panel>
				</a-collapse>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { mask } from 'vue-the-mask'
import utilities from '@/services/utilities'
import { taxRegimes } from '@/constants'

export default {
	name: 'clientsDetailBillingInformationComponent',
	props: {
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	directives: {
		mask,
	},
	computed: {
		...mapGetters('clients', ['actualClient']),
		hasClientId() {
			return !!utilities.objectValidate(this.actualClient, 'id', false)
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			taxRegimes,
			showCancelButton: false,
			actualTaxInformation: 0,
		}
	},
	methods: {
		onCancel() {
			this.$router.replace('/pos/clients')
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					if (isNaN(values.tax_regime)) {
						values.tax_regime = Number(values.tax_regime.split(' - ')[0])
					}
					let selectedTaxRegime = taxRegimes.find((e) => e.id == values.tax_regime)

					let payload = {
						business_name: values.business_name,
						rfc: values.rfc,
						tax_regime: Number(values.tax_regime),
						tax_regime_label: `${selectedTaxRegime.id} - ${selectedTaxRegime.description}`,
						street_name: utilities.objectValidate(values, 'street_name', ''),
						external_number: utilities.objectValidate(values, 'external_number', ''),
						internal_number: utilities.objectValidate(values, 'internal_number', ''),
						neighborhood: utilities.objectValidate(values, 'neighborhood', ''),
						locality: utilities.objectValidate(values, 'locality', ''),
						state: utilities.objectValidate(values, 'state', ''),
						zip_code: values.zip_code,
						email: values.email,
					}

					if (this.showCancelButton) {
						payload.id = this.actualClient.billing_information[this.actualTaxInformation].id
						await this.$store.dispatch('clients/UPDATE_BILLING_INFORMATION', { clientId: this.actualClient.id, payload })
					} else {
						await this.$store.dispatch('clients/CREATE_BILLING_INFORMATION', { clientId: this.actualClient.id, payload })
					}
					this.onCancelEdit()
				}
			})
		},
		setLocalData(values) {
			this.form.setFieldsValue({
				business_name: values.business_name,
				rfc: values.rfc,
				tax_regime: values.tax_regime_label,
				street_name: utilities.objectValidate(values, 'street_name', ''),
				external_number: utilities.objectValidate(values, 'external_number', ''),
				internal_number: utilities.objectValidate(values, 'internal_number', ''),
				neighborhood: utilities.objectValidate(values, 'neighborhood', ''),
				locality: utilities.objectValidate(values, 'locality', ''),
				state: utilities.objectValidate(values, 'state', ''),
				zip_code: utilities.objectValidate(values, 'zip_code', ''),
				email: values.email,
			})
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onCancelEdit() {
			this.form.resetFields()
			this.showCancelButton = false
		},
		onEditElement(index) {
			let values = _.cloneDeep(this.actualClient.billing_information[index])
			this.actualTaxInformation = index
			this.showCancelButton = true
			this.setLocalData(values)
		},
		onDeleteElement(index) {
			let id = this.actualClient.billing_information[index].id
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar los datos fiscales del cliente?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('clients/DELETE_BILLING_INFORMATION', { clientId: this.actualClient.id, id })
				}
			})
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>

<style lang="scss" scopped>
.ant-collapse-content-box {
	padding: 0 !important;
}
</style>